import React, { useEffect, useState } from "react";
import Button from "../../extra/Button";
import Input from "../../extra/Input";
import { CLOSE_DIALOGUE } from "../../store/dialogue/dialogue.type";
import { connect, useDispatch, useSelector } from "react-redux";
import { permissionError } from "../../../util/Alert";
import {
  createWithdraw,
  updateWithdraw,
} from "../../store/withdraw/withdraw.action";
import { createCurrency, updateCurrency } from "../../store/currency/currency.action";

const CurrencySettingDialog = (props) => {
  const dispatch = useDispatch();
  const { dialogueData } = useSelector((state) => state.dialogue);
  const hasPermission = useSelector((state) => state.admin.admin.flag);
  const [mongoId, setMongoId] = useState("");
  const [name, setName] = useState();
  const [symbol , setSymbol] = useState();
  const [currencyCode , setCurrencyCode] = useState();
  const [countryCode , setCountryCode] = useState();

  const [error, setError] = useState({
    name: "",
    symbol : "",
    currencyCode : "",
    countryCode : "",
    imagePath: "",
    addDetail: "",
  });

  useEffect(() => {
    setMongoId(dialogueData?._id);
    setName(dialogueData?.name);
    setSymbol(dialogueData?.symbol)
    setCurrencyCode(dialogueData?.currencyCode);
    setCountryCode(dialogueData?.countryCode);
  }, [dialogueData]);




  const handleSubmit = (e) => {
    e.preventDefault();
    if (!name || !symbol || !currencyCode || !countryCode) {
      let error = {};
      if (!name) error.name = "Name is required!";
      if (!symbol) error.symbol = "Symbol is required!";
      if (!currencyCode) error.currencyCode = "Currency Code is required!";
      if (!countryCode) error.countryCode = "Country Code is required!";


      setError({ ...error });
    } else {
      if (!hasPermission) return permissionError();

      const data = {
        name,
        symbol,
        currencyCode,
        countryCode
      }
        

    if (mongoId) {
        dispatch(updateCurrency(data, mongoId));
      } else {
        dispatch(createCurrency(data));
      }

      dispatch({ type: CLOSE_DIALOGUE });
    }
  };

  return (
    <div className="mainDialogue fade-in">
      <div className="Dialogue">
        <div className="dialogueHeader">
          <div className="headerTitle fw-bold">Currency</div>
          <div
            className="closeBtn "
            onClick={() => {
              dispatch({ type: CLOSE_DIALOGUE });
            }}
          >
            <i class="fa-solid fa-xmark"></i>
          </div>
        </div>
        <div className="dialogueMain">
          <div className="row">
            <div className="col-12">
              <Input
                label={`Name`}
                id={`name`}
                type={`text`}
                value={name}
                errorMessage={error.name && error.name}
                onChange={(e) => {
                  setName(e.target.value);
                  if (!e.target.value) {
                    return setError({
                      ...error,
                      name: `Name Is Required`,
                    });
                  } else {
                    return setError({
                      ...error,
                      name: "",
                    });
                  }
                }}
              />
            </div>
          </div>

          <div className="row">
            <div className="col-12">
              <Input
                label={`Symbol`}
                id={`name`}
                type={`text`}
                value={symbol}
                errorMessage={error.symbol && error.symbol}
                onChange={(e) => {
                  setSymbol(e.target.value);
                  if (!e.target.value) {
                    return setError({
                      ...error,
                      symbol: `Symbol Is Required`,
                    });
                  } else {
                    return setError({
                      ...error,
                      symbol: "",
                    });
                  }
                }}
              />
            </div>
          </div>

          <div className="row">
            <div className="col-12">
              <Input
                label={`Currency Code`}
                id={`currency code`}
                type={`text`}
                value={currencyCode}
                errorMessage={error.currencyCode && error.currencyCode}
                onChange={(e) => {
                  setCurrencyCode(e.target.value);
                  if (!e.target.value) {
                    return setError({
                      ...error,
                      currencyCode: `Currecny Code Is Required`,
                    });
                  } else {
                    return setError({
                      ...error,
                      currencyCode: "",
                    });
                  }
                }}
              />
            </div>
          </div>

          <div className="row">
            <div className="col-12">
              <Input
                label={`Country Code`}
                id={`country code`}
                type={`text`}
                value={countryCode}
                errorMessage={error.countryCode && error.countryCode}
                onChange={(e) => {
                  setCountryCode(e.target.value);
                  if (!e.target.value) {
                    return setError({
                      ...error,
                      countryCode: `Country Code Is Required`,
                    });
                  } else {
                    return setError({
                      ...error,
                      countryCode: "",
                    });
                  }
                }}
              />
            </div>
          </div>
        </div>
        <div className="dialogueFooter">
          <div className="dialogueBtn">
            <Button
              btnName={`Submit`}
              btnColor={`btnBlackPrime text-dark`}
              style={{ borderRadius: "5px", width: "80px" }}
              newClass={`me-2`}
              onClick={handleSubmit}
            />
            <Button
              btnName={`Close`}
              btnColor={`bg-danger text-white`}
              style={{ borderRadius: "5px", width: "80px" }}
              onClick={() => {
                dispatch({ type: CLOSE_DIALOGUE });
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default connect(null, { createWithdraw, updateWithdraw })(
  CurrencySettingDialog
);

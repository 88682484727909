import React, { useEffect, useState } from "react";
import Table from "../../../extra/Table";
import Pagination from "../../../extra/Pagination";
import { connect, useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { colors } from "../../../../util/SkeletonColor";
import { getSellerOrder } from "../../../store/seller/seller.action";
import Skeleton from "react-loading-skeleton";
import Button from "../../../extra/Button";
import Info from "../../../../assets/images/Info.svg"
import { getDefaultCurrency } from "../../../store/currency/currency.action";

const SellerOrder = (props) => {
  const { sellerOrder } = useSelector((state) => state.seller);
  const { state } = useLocation();

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [size, setSize] = useState(10);
  const [page, setPage] = useState(1);
  const [data, setData] = useState([]);
  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [status, setStatus] = useState("All");
  const [loading, setLoading] = useState(true);

  const { defaultCurrency } = useSelector((state) => state.currency)

  useEffect(() => {
    dispatch(getSellerOrder(state, currentPage, size, status));
    dispatch(getDefaultCurrency())
  }, [dispatch, state, currentPage, size, status]);

  useEffect(() => {
    setData(sellerOrder);
  }, [sellerOrder]);

  // // pagination
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event, 10));
    setPage(0);
  };

  const handleOpen = (id) => {
    navigate("/admin/order/orderDetail", {
      state: id,
    });
  };

  // table Data
  let date;

  const mapData = [
    {
      Header: "No",
      width: "20px",
      Cell: ({ index }) => <span className="fw-normal text-white">{index + 1}</span>,
    },

    {
      Header: "Order Id",
      body: "orderId",
      Cell: ({ row }) => (
        <div className="">
          <p className="mb-0 fw-normal text-white">{row.orderId}</p>
        </div>
      ),
    },
    {
      Header: "User",
      body: "userFirstName",
      Cell: ({ row }) => (
        <div className="">
          <p className="mb-0 fw-normal text-white">{row.userFirstName + " " + row.userLastName}</p>
        </div>
      ),
    },
    {
      Header: "Items",
      body: "items",
      Cell: ({ row }) => (
        <div>
          {row?.items?.map((data, index) => (
            <div
              key={index}
              className="py-2"
              style={{
                borderBottom: index < row.items.length - 1 ? "1px solid #606060" : "",
              }}
            >
              <div className="d-flex align-items-center">
                {/* Product Image */}
                <img
                  src={data.productId?.mainImage || "placeholder.png"} // Fallback for missing image
                  width={55}
                  height={55}
                  style={{
                    borderRadius: "10px",
                    margin: "5px",
                  }}
                  alt={data.productId?.productName || "Product"}
                />
                {/* Product Details */}
                <div className="ms-3 text-start">
                  <b className="fs-6 fw-normal text-white">
                    {data.productId?.productName || "Unknown Product"}
                  </b>
                  <br />
                  <span style={{ fontSize: "13px" }} className="text-white">
                    <b className="fw-normal">Quantity:</b> {data?.productQuantity}
                  </span>
                  <br />
                  <span style={{ fontSize: "13px" }} className="text-white">
                    <b className="fw-normal">Price:</b> ₹{data?.purchasedTimeProductPrice}
                  </span>
                </div>
              </div>
            </div>
          ))}
        </div>
      ),
    },

    {
      Header: "PaymentGateway",
      body: "paymentGateway",
      Cell: ({ row }) => (
        <div className="">
          <p className="mb-0 fw-normal text-white">{row.paymentGateway}</p>
        </div>
      ),
    },
    {
      Header: "Status",
      body: "status",
      Cell: ({ row }) =>
        row.items.map((data) => {
          return (
            <>
              <div className="py-4 boxCenter">
                {(data?.status === "Pending" && (
                  <span className="badge badge-primary p-2">Pending</span>
                )) ||
                  (data?.status === "Confirmed" && (
                    <span className="badge badge-success p-2">Confirmed</span>
                  )) ||
                  (data?.status === "Cancelled" && (
                    <span className="badge badge-danger p-2">Cancelled</span>
                  )) ||
                  (data?.status === "Out Of Delivery" && (
                    <span className="badge badge-warning p-2">
                      Out Of Delivery
                    </span>
                  )) ||
                  (data?.status === "Delivered" && (
                    <span className="badge badge-info p-2">Delivered</span>
                  ))}
              </div>
            </>
          );
        }),
    },
    {
      Header: "Info",
      body: "",
      Cell: ({ row }) => (
        <>
          <Button
            newClass={`themeFont boxCenter userBtn fs-5`}
            btnIcon={Info}
            style={{
              borderRadius: "8px",
              margin: "auto",
              height: "45px",
              width: "45px",
              color: "#160d98",
              background: "#C4F3FF",
              padding: "0px"
            }}
            isImage={true}
            isDeleted={true}
            onClick={() => handleOpen(row?._id)}

          />
        </>
      ),
    },

    // add more columns as needed
  ];



  return (
    <>
      <div className="row">
        <div className="col-12">
          <div className="btn-group p-3 ms-2">
            <button
              type="button"
              className="btn btnthemePrime dropdown-toggle"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              {status ? (
                <span className="caret">{status}</span>
              ) : (
                <span className="caret text-capitalize">Status</span>
              )}
            </button>
            <ul className="dropdown-menu">
              <li style={{ cursor: "pointer" }}>
                <a
                  className="dropdown-item"
                  href={() => false}
                  onClick={() => setStatus("Pending")}
                >
                  Pending
                </a>
              </li>
              <li style={{ cursor: "pointer" }}>
                <a
                  className="dropdown-item"
                  href={() => false}
                  onClick={() => setStatus("Confirmed")}
                >
                  Confirmed
                </a>
              </li>
              <li style={{ cursor: "pointer" }}>
                <a
                  className="dropdown-item"
                  href={() => false}
                  onClick={() => setStatus("Out Of Delivery")}
                >
                  Out Of Delivery
                </a>
              </li>
              <li style={{ cursor: "pointer" }}>
                <a
                  className="dropdown-item"
                  href={() => false}
                  onClick={() => setStatus("Delivered")}
                >
                  Delivered
                </a>
              </li>
              <li style={{ cursor: "pointer" }}>
                <a
                  className="dropdown-item"
                  href={() => false}
                  onClick={() => setStatus("Cancelled")}
                >
                  Cancelled
                </a>
              </li>
              <li style={{ cursor: "pointer" }}>
                <a
                  className="dropdown-item"
                  href={() => false}
                  onClick={() => setStatus("All")}
                >
                  All
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="userMain">
        <div className="tableMain mt-2">
          <div className="card-body p-0">
            <div className="dashBoardTable">
              <div className="tableMain">
                <div className="primeMain">
                  <table
                    width="100%"
                    border
                    className="primeTable text-center"
                    style={{ maxHeight: "680px" }}
                  >
                    <thead
                      className="sticky-top"
                      style={{ top: "-1px", zIndex: "1" }}
                    >
                      <tr>
                        <th className="fw-bold py-3" style={{}}>
                          No
                        </th>
                        <th className="fw-bold py-3" style={{ width: "330px" }}>
                          Order Id
                        </th>
                        <th className="fw-bold py-3" style={{ width: "330px" }}>
                          User
                        </th>
                        <th className="fw-bold py-3" style={{ width: "450px" }}>
                          Items
                        </th>
                        <th className="fw-bold py-3" style={{ width: "330px", textAlign: "end" }}>
                          PaymentGateway
                        </th>

                        <th className="fw-bold py-3" style={{ width: "330px", textAlign: "center" }}>
                          Status
                        </th>

                        <th className="fw-bold py-3" style={{ width: "330px" , textAlign: "end" , paddingRight : "30px" }}>
                          Info
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {data?.length > 0 ? (
                        <>
                          {data?.map((mapData, index) => {
                            return (
                              <>
                                <tr>
                                  <td>
                                    <span className="text-white">{index + 1}</span>
                                  </td>
                                  <td width="160px" className="">
                                    <span className="tableBoldFont orderId">
                                      <b
                                        className="fw-normal text-white orderIdText"
                                        onClick={() => handleOpen(mapData)}
                                        style={{
                                          cursor: "pointer",
                                        }}
                                      >
                                        {mapData?.orderId}
                                      </b>
                                    </span>
                                  </td>
                                  <td style={{ width: "350px" }}>
                                    {

                                  mapData.userFirstName + " " + mapData.userLastName
                                    }
                                  </td>
                                  <td
                                    colSpan={6}
                                    style={{ width: "70%" }}
                                    className="py-0"
                                  >
                                    {mapData?.items.map((data) => {

                                      console.log("data" , data?.productId?.mainImage)

                                      return (
                                        <>
                                          <div className="">
                                            <table
                                              width="100%"
                                              border
                                              className=" text-center"
                                            >
                                              <tbody>
                                                <tr
                                                  style={{
                                                    borderLeft:
                                                      "2px solid #f3f3f3",
                                                  }}
                                                >
                                                  <td
                                                    className="my-2"
                                                    style={{ width: "360px" }}
                                                  >
                                                    <div className="">
                                                      <div className="d-flex justify-content-center">
                                                       
                                                       
                                                          <>
                                                            <img
                                                              src={
                                                                data.productId
                                                                  ?.mainImage
                                                              }
                                                              width={55}
                                                              height={55}
                                                              style={{
                                                                borderRadius:
                                                                  "10px",
                                                                margin: "5px",
                                                                objectFit:
                                                                  "cover",
                                                              }}
                                                              alt=""
                                                              srcset=""
                                                            />
                                                          </>
                                                       
                                                        <div className="ms-3 text-start">
                                                          <b className="fs-6 text-white text-center">
                                                            {
                                                              data.productId
                                                                ?.productName
                                                            }
                                                          </b>
                                                          <br />
                                                          <span
                                                            style={{
                                                              fontSize: "13px",
                                                              color: "#FFF",
                                                            }}
                                                          >
                                                            <b className="text-white text-center">
                                                              Quantity
                                                            </b>
                                                            :
                                                            {
                                                              data?.productQuantity
                                                            }
                                                          </span>
                                                          <br />
                                                          <span
                                                            style={{
                                                              fontSize: "13px",
                                                              color: "#FFF",
                                                            }}
                                                          >
                                                            <b className="text-white">
                                                              Price
                                                            </b>
                                                            :
                                                            {
                                                              data?.purchasedTimeProductPrice
                                                            }

                                                          </span>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </td>
                                                  <td
                                                    className="my-2"
                                                    style={{ width: "350px", }}
                                                  >
                                                    <div className="">
                                                      <b className="fs-6 text-white text-align-center">
                                                        {
                                                          mapData?.paymentGateway
                                                        }
                                                      </b>
                                                    </div>
                                                  </td>
                                              
                                                  <td
                                                   className="my-2"
                                                   style={{ width: "400px", }}
                                                  >
                                                    <div className="">
                                                      {(data?.status === "Pending" && (
                                                        <span className="badge badge-primary p-2">Pending</span>
                                                      )) ||
                                                        (data?.status === "Confirmed" && (
                                                          <span className="badge badge-success p-2">Confirmed</span>
                                                        )) ||
                                                        (data?.status === "Cancelled" && (
                                                          <span className="badge badge-danger p-2">Cancelled</span>
                                                        )) ||
                                                        (data?.status === "Out Of Delivery" && (
                                                          <span className="badge badge-warning p-2">
                                                            Out Of Delivery
                                                          </span>
                                                        )) ||
                                                        (data?.status === "Delivered" && (
                                                          <span className="badge badge-info p-2">Delivered</span>
                                                        ))}
                                                    </div>
                                                  </td>

                                                  <td 
                                                        
                                                  >
                                                    <Button
                                                      newClass={`themeFont boxCenter userBtn fs-5`}
                                                      btnIcon={Info}
                                                      style={{
                                                        borderRadius: "8px",
                                                        height: "45px",
                                                        width: "45px",
                                                        color: "#160d98",
                                                        background: "#C4F3FF",
                                                        padding: "0px",

                                                      }}
                                                      isImage={true}
                                                      isDeleted={true}
                                                      onClick={() => handleOpen(mapData?._id)}

                                                    />
                                                  </td>
                                                </tr>
                                              </tbody>
                                            </table>
                                          </div>
                                        </>
                                      );
                                    })}
                                  </td>
                                </tr>
                              </>
                            );
                          })}
                        </>
                      ) : (
                        <tr>
                          <td colSpan="25" className="text-center text-white">
                            No Data Found !
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
                {/* <Pagination
                component="div"
                count={totalOrder}
                totalData={totalOrder}
                type={"server"}
                onPageChange={handleChangePage}
                serverPerPage={rowsPerPage}
                serverPage={currentPage}
                setCurrentPage={setCurrentPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              /> */}
                {/* {dialogue && dialogueType === "order" && (
                <EditOrder statusData={sendStatus} />
              )} */}
              </div>
              {/* <div className="tableMain m-0">
                <Table
                  data={data}
                  mapData={mapData}
                  serverPerPage={rowsPerPage}
                  serverPage={page}
                  type={"server"}
                />
              </div> */}
            </div>
          </div>
          <Pagination
            count={sellerOrder?.length}
            type={"server"}
            onPageChange={handleChangePage}
            serverPerPage={rowsPerPage}
            totalData={sellerOrder?.length}
            serverPage={currentPage}
            setCurrentPage={setCurrentPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </div>
      </div>
    </>
  );
};

export default connect(null, { getSellerOrder })(SellerOrder);

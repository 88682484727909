import Table from "../../extra/Table";
import Button from "../../extra/Button";
import { connect, useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { OPEN_DIALOGUE } from "../../store/dialogue/dialogue.type";
import ToggleSwitch from "../../extra/ToggleSwitch";
import dayjs from "dayjs";
import Pagination from "../../extra/Pagination";
import { permissionError, warning } from "../../../util/Alert";
import EditInfo from "../../../assets/images/Edit.png";
import "react-loading-skeleton/dist/skeleton.css";
import CurrencySettingDialog from "./currencySettingDialog";
import Delete from "../../../assets/images/Delete.svg"
import { deleteCurrency, enabledDisabled, getCurrency, getDefaultCurrency } from "../../store/currency/currency.action";

const CurrencySetting = (props) => {
    const [page, setPage] = useState(0);
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const dispatch = useDispatch();

    const { currency } = useSelector((state) => state.currency);

    console.log("currency", currency)

    const { dialogue, dialogueType, dialogueData } = useSelector(
        (state) => state.dialogue
    );
    const hasPermission = useSelector((state) => state.admin.admin.flag);

    useEffect(() => {
        dispatch(getCurrency());
    }, [dispatch]);

    useEffect(() => {
        const timer = setTimeout(() => {
            setLoading(false);
        }, 1500); // Adjust the delay time as needed

        return () => clearTimeout(timer);
    }, []);

    useEffect(() => {
        setData(currency);
    }, [currency]);

    // // pagination
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event, 10));
        setPage(0);
    };

    const handleClick = (currencyDetail) => {
        if (!hasPermission) return permissionError();
       dispatch(enabledDisabled(currencyDetail));
    };

    // Delete fake reels
    const handleDelete = (id) => {
        if (!hasPermission) return permissionError();

        const data = warning();
        data
            .then((isDeleted) => {
                if (isDeleted) {
                    dispatch(deleteCurrency(id));
                }
            })
            .catch((err) => console.log(err));
    };

    // Delete CurrencySetting

    const mapData = [
        {
            Header: "No",
            width: "20px",
            Cell: ({ index }) => <span className="text-white">{parseInt(index) + 1}</span>,
        },

        {
            Header: "Name",
            body: "name",
            Cell: ({ row }) => (
                <span className="text-white">{row?.name}</span>
            ),
        },

        {
            Header: "Symbol",
            body: "symbol",
            Cell: ({ row }) => (
                <span className="text-white">{row?.symbol ? row?.symbol : "-"}</span>
            ),
        },

        {
            Header: "Currency Code",
            body: "currency code",
            Cell: ({ row }) => (
                <span className="text-white">{row?.currencyCode ? row?.currencyCode : "-"}</span>
            ),
        },

        {
            Header: "Country Code",
            body: "country code",
            Cell: ({ row }) => (
                <span className="text-white">{row?.countryCode ? row?.countryCode : "-"}</span>
            ),
        },

        {
            Header: "Is Default",
            body: "is default",
            Cell: ({ row }) => (
                <>
                    <div className="boxCenter">
                        <ToggleSwitch
                            value={row?.isDefault}
                            onClick={() => handleClick(row)}
                        />
                    </div>
                </>
            ),
        },

        {
            Header: "CreatedDate",
            body: "createdAt",
            Cell: ({ row }) => (
                <span className="text-white">{dayjs(row?.createdAt).format("DD MMM YYYY")}</span>
            ),
        },
        {
            Header: "Action",
            body: "",
            Cell: ({ row }) => (
                <div className="d-flex justify-content-between">
                    <Button
                        newClass={`themeFont boxCenter userBtn fs-5`}
                        btnIcon={EditInfo}
                        style={{
                            borderRadius: "8px",
                            margin: "auto",
                            height: "45px",
                            width: "45px",
                            color: "#160d98",
                            background: "#C7E2FF"

                        }}
                        isImage={true}
                        onClick={() =>
                            dispatch({
                                type: OPEN_DIALOGUE,
                                payload: { data: row, type: "currency" },
                            })
                        }

                    />

                    <Button
                        newClass={`themeFont boxCenter userBtn fs-5`}
                        btnIcon={Delete}
                        style={{
                            borderRadius: "8px",
                            margin: "auto",
                            height: "45px",
                            width: "45px",
                            color: "#160d98",
                            background: "#FFDFDF",
                            padding: "0px"

                        }}
                        isImage={true}
                        isDeleted={true}
                        onClick={() => handleDelete(row?._id)}

                    />
                    {dialogue && dialogueType === "currency" && <CurrencySettingDialog />}
                </div>
            ),
        },


        // add more columns as needed
    ];
    return (
        <>
            <div className="mainSellerTable">
                <div className="sellerTable">
                    <div className="sellerHeader primeHeader">
                        <div className="row">
                            <div className="col-10">
                                <Button
                                    newClass={`whiteFont`}
                                    btnColor={`btnBlackPrime`}
                                    btnIcon={`fa-solid fa-plus`}
                                    btnName={`Add`}
                                    onClick={() => {
                                        dispatch({
                                            type: OPEN_DIALOGUE,
                                            payload: { type: "currency" },
                                        });
                                    }}
                                    style={{ borderRadius: "10px" }}
                                />
                                {dialogue && dialogueType === "currency" && <CurrencySettingDialog />}
                            </div>
                            <div className="col-2 text-end"></div>
                        </div>
                    </div>
                    <div className="sellerMain">
                        <div className="tableMain mt-2">
                            <Table
                                data={data}
                                mapData={mapData}
                                PerPage={rowsPerPage}
                                Page={page}
                                type={"client"}
                            />
                            {/* <Pagination
                                component="div"
                                count={currency?.length}
                                serverPage={page}
                                type={"client"}
                                onPageChange={handleChangePage}
                                serverPerPage={rowsPerPage}
                                totalData={currency?.length}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                            /> */}
                        </div>
                    </div>
                    <div className="sellerFooter primeFooter"></div>
                </div>
            </div>
        </>
    );
};

export default connect(null, { getCurrency, enabledDisabled })(CurrencySetting);

// get_ banner

export const GET_CURRENCY = "GET_CURRENCY";

export const GET_DEFAULT_CURRENCY = "GET_DEFAULT_CURRENCY";


//  create withdraw
export const CREATE_CURRENCY = "CREATE_CURRENCY";

// update withdraw
export const UPDATE_CURRENCY = "UPDATE_CURRENCY";

// delete withdraw
export const DELETE_CURRENCY = "DELETE_CURRENCY";

export const DEFAULT_CURRENCY = "DEFAULT_CURRENCY";

